<template>
  <div>
    <div>
      <button @click="reset">Zurücksetzen</button>
      <h2>Leergut zurück:</h2>
      <div>
        <div>Krüge:</div>
        <button @click="incrementFirst">+</button>
        <span>{{ firstValue }}</span>
        <button @click="decrementFirst">-</button>
      </div>
      <div>
        <div>Flaschen:</div>
        <button @click="incrementSecond">+</button>
        <span>{{ secondValue }}</span>
        <button @click="decrementSecond">-</button>
      </div>
      <h3>Pfand zurück: {{ firstValue * 5 + secondValue * 2}} €</h3>
    </div>
    <div>
      <h2>Getränke:</h2>
      <div>
        <div>Bier, Radler, Weizen:</div>
        <button @click="incrementThird">+</button>
        <span>{{ thirdValue }}</span>
        <button @click="decrementThird">-</button>
      </div>
      <div>
        <div>Spezi, Limo, Apfelschorle:</div>
        <button @click="incrementFourth">+</button>
        <span>{{ fourthValue }}</span>
        <button @click="decrementFourth">-</button>
      </div>
      <div>
        <div>Wasser:</div>
        <button @click="incrementFifth">+</button>
        <span>{{ fifthValue }}</span>
        <button @click="decrementFifth">-</button>
      </div>
      <h2>Zu Zahlen: {{ (thirdValue * (4+5) + fourthValue * (3+2) + fifthValue * (2.5+2))
      - (firstValue * 5 + secondValue * 2) }} €</h2>
      <h3>(davon  {{ thirdValue * (5) + fourthValue * (2) + fifthValue * (2)
      - (firstValue * 5 + secondValue * 2) }} € Pfand)</h3>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';

export default {
  name: 'SumComponent',
  setup() {
    const firstValue = ref(0);
    const secondValue = ref(0);
    const thirdValue = ref(0);
    const fourthValue = ref(0);
    const fifthValue = ref(0);

    const incrementFirst = () => firstValue.value += 1;
    const decrementFirst = () => firstValue.value -= 1;
    const incrementSecond = () => secondValue.value += 1;
    const decrementSecond = () => secondValue.value -= 1;
    const incrementThird = () => thirdValue.value += 1;
    const decrementThird = () => thirdValue.value -= 1;
    const incrementFourth = () => fourthValue.value += 1;
    const decrementFourth = () => fourthValue.value -= 1;
    const incrementFifth = () => fifthValue.value += 1;
    const decrementFifth = () => fifthValue.value -= 1;

    const reset = () => {
      firstValue.value = 0;
      secondValue.value = 0;
      thirdValue.value = 0;
      fourthValue.value = 0;
      fifthValue.value = 0;
    };

    return {
      firstValue,
      secondValue,
      thirdValue,
      fourthValue,
      fifthValue,
      incrementFirst,
      decrementFirst,
      incrementSecond,
      decrementSecond,
      incrementThird,
      decrementThird,
      incrementFourth,
      decrementFourth,
      incrementFifth,
      decrementFifth,
      reset,
    };
  },
};
</script>

<style scoped>
div {
  margin: 10px 0;
}
button {
  margin: 0 5px;
}
span {
  margin: 0 10px;
  font-weight: bold;
}
</style>
